
import PrintPage from './printPage.jsx';
import { useState } from 'react';
import CondoInfoForm from './info/condoInfo.jsx'
import {CashFlowAnalysis} from './analysis/cashFlowAnalysis.jsx';
function CondoCalculator(props) {
    const [sentData, setSentData] = useState(true)
    const [formData, setFormData] = useState(
        {
            address: "",
            bedrooms: 0,
            indoorSQFT: 0,
            outdoorSQFT: 0,
            price: 0,

            deposit1: 0,
            deposit2: 0,
            deposit3: 0,
            deposit4: 0,
            deposit5: 0,
            deposit6: 0,

            maintenance: 0,
            municipalTax: 0,
            mortgage: 0,

            incomingRent: 0,
            Price: 0,
            Parking: 0,
            Locker: 0,

            rentPrice: 0
        }
    )


    function handleSubmit(event) {
        event.preventDefault();
        setSentData(!sentData)
    }

    function handleChange(event) {
        const { name, value, type, checked } = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    let prices = [formData.Price, formData.Parking, formData.Locker]
    let expenses = [formData.maintenance, formData.municipalTax, formData.mortgage]
    
    return (
        <div>
            {sentData ? <CondoInfoForm formData={formData} handleChange={handleChange} name={props.name} handleSubmit={handleSubmit} prices= {prices} expenses={expenses}/>
                :  <CashFlowAnalysis formData={formData} handleChange={handleChange} name={props.name} 
                setPrint={props.print} handleSubmit={handleSubmit} prices= {prices} expenses={expenses} />
            }
        </div>

    );
}

export default CondoCalculator;
