function sum(data) {
    let i = 0;
    for (let x in data) {
        if(!isNaN(x)){
            i += parseFloat(data[x])
        }
    }
    return i
}

export { sum }