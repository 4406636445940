import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import CondoCalculator from './components/condoCalculator';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LandingCard from './components/landingCard';
import { useState } from 'react';
import PrintPage from './components/printPage';
import './App.css'
function App() {
  const [cardData, setCardData] = useState(
    {
      card1Name : "Condo 1",
      card2Name : "Condo 2",
      card3Name : "Condo 3",
    }
  )
  const [card1, setCard1] = useState(true)
  const [card2, setCard2] = useState(true)
  const [card3, setCard3] = useState(true)

function handleChange(event) {
  const { name, value, type, checked } = event.target
  setCardData(prevFormData => {
      return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value
      }
  })
}
const [printData, setPrintData] = useState(true)
  return (
    <Container fluid className="bIg px-3">
      {/* <header>
	<div className="overlay">
      <h1>Condo Calculator</h1>
		</div>
</header> */}

      <Row className="justify-content-center py-5" xs={1} lg={3} md={2}>
        <Col >
        {  card1 ? <LandingCard  setterC={()=>setCard1(!card1)}  handleChange={handleChange} name={"card1Name"} value={cardData.card1Name}/> : 
         <CondoCalculator name={cardData.card1Name} print={()=>setPrintData(!printData)} printData= {printData} /> }
        </Col>
        <Col >
        {  card2 ? <LandingCard  setterC={()=>setCard2(!card2)} handleChange={handleChange} name={"card2Name"} value={cardData.card2Name}/> : 
        <CondoCalculator name={cardData.card2Name} print={()=>setPrintData(!printData)} printData= {printData}/> }
        
        </Col >
        <Col >
        {   card3 ? <LandingCard  setterC={()=>setCard3(!card3)} handleChange={handleChange} name={"card3Name"} value={cardData.card3Name}/> :
         <CondoCalculator name={cardData.card3Name} print={()=>setPrintData(!printData)} printData= {printData}/> }
        </Col>
      </Row>
    </Container>
  );
}
export default App;
