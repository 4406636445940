import Accordion from 'react-bootstrap/Accordion';
import FormElem from './FormElement';

function BasicExample(props) {
    let counter = 0;
    const listItems = props.collapseList.map(item => {
        return (
            <FormElem
                key={counter++}
                handleChange={props.handleChange}
                name={props.listName[counter]}
                value={props.setValue({ item })}
                ID={props.listName[counter]}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
                step={props.step}
                required={props.required}
                place={props.place[counter]}
            />
        )
    })

    return (

        <Accordion defaultActiveKey="0" className="pb-3">
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    {props.buttonName}
                </Accordion.Header>
                <Accordion.Body>
                    {props.inputPrice}
                    {listItems}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    );
}

export default BasicExample;