import Form from 'react-bootstrap/Form'


export default function FormElem(props) {
    return (

        <Form.Group className="mb-3" controlId={props.ID} >
            {/* <FloatingLabel
        controlId={props.ID}
        label={props.ID}
        className="mb-1"
                > */}
            <Form.Control className="rounded-3 fs-5" type={props.type} step={props.step} min={props.min} max={props.max} placeholder={props.place}
                onChange={props.handleChange}
                name={props.name}
                value={props.value}
                disabled={props.disabled}
                required ={props.required}
            />
            {/* </FloatingLabel> */}
        </Form.Group>
    )
}

FormElem.defaultProps = {
    type: "number"
};