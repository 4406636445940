
import FormElem from './FormElement';

function FormElemGroup(props) {

    let counter = 0;
    const listItems = props.collapseList.map(item => {
        return (
            <FormElem
                key={counter++}
                handleChange={props.handleChange}
                name={props.listName[counter]}
                value={props.setValue({ item })}
                ID={props.listName[counter]}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
                step={props.step}
                required={props.required}
                place={props.place[counter]}
            />
        )
    })

    return (
        listItems
    );
}

export default FormElemGroup;