let analysisItems = [
    "Break Even",
    "Cost per bedroom",
    "Rent PSF",
    "Monthly Cash Flow",
    "Annual Cash Flow",
]

let monthlyItems = [
    "Fees $/sqft", //insert popover
    "Tax",
    "MTG", //rate of mortgage
]

let priceItems = [
    "Price",
    "Parking",
    "Locker",
]

let depositItems = [
    "Occupancy Deposit", //popover first deposit
    "Deposit 1",
    "Deposit 2",
    "Deposit 3",
    "Deposit 4",
    "Deposit 5",
]

export { priceItems, depositItems, monthlyItems, analysisItems }