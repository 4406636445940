
import Button from 'react-bootstrap/Button';
import './cardLand.css'

import Form from 'react-bootstrap/Form'

export default function LandingCard(props){
    function handleClick() {
        props.setterC()
    }
    return (
        <div className="containerR">
  <div className="cardR">
     <div className="card__image-containerR">
       <img className="card__imageR" src="https://www.condocalculator.ca/wp-content/uploads/Toronto_at_Night-CondoCalculator.ca_.jpeg" alt=""/>
    </div>
      
      <svg className="card__svgR" viewBox="0 70 800 500">

        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333"/>
        <path className="card__lineR" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="3" fill="transparent"/>
      </svg>
    
     <div className="card__contentR">
       <h1 className="card__titleR">Project</h1>
<Form className="pb-4">
<Form.Control placeholder="Condo"
                onChange={props.handleChange}
                name={props.name}
                value={props.value}
            />
</Form>
     <Button variant="primary" onClick={handleClick}>Calculate</Button>
    </div>
    
  </div>

</div>
      );
}
