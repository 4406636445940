import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './dB.css'
function DropdownResult(props) {
  
const numberFormatter = Intl.NumberFormat('en-US')

  let counter = 0;
  let dropDownItems = props.itemList.map(item=>{
    return(
      <Dropdown.Item key={counter++}> 
      {`${item}: $${numberFormatter.format(props.itemsValue[counter])}`}
      </Dropdown.Item>
    )
  })
        
    return (
      <DropdownButton variant="warning" title={`${props.title}: $${numberFormatter.format(props.sum)}`} className="pb-3 dB">
        {dropDownItems}
      </DropdownButton>
    );
  }
  
  export default DropdownResult;