import Dropdown from 'react-bootstrap/Dropdown';

import './dB.css'
import Accordion from 'react-bootstrap/Accordion';
function DropdownResult2(props) {
  
const numberFormatter = Intl.NumberFormat('en-US')

  let counter = 0;
  let dropDownItems = props.itemList.map(item=>{
    return(
      <Dropdown.Item key={counter++}> 
      {`${item}: $${numberFormatter.format(props.itemsValue[counter])}`}
      <br></br>
      
      </Dropdown.Item>
    )
  })
        
    return (
        <Accordion defaultActiveKey="1" className="pb-3">
        <Accordion.Item eventKey="1">
            <Accordion.Header>
            {`${props.title}: $${numberFormatter.format(props.sum)}`}
            </Accordion.Header>
            <Accordion.Body className="fs-4">
            {dropDownItems}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    //   <DropdownButton variant="warning" title={`${props.title}: $${numberFormatter.format(props.sum)}`} className="pb-3 dB">
    //     {dropDownItems}
    //   </DropdownButton>
    );
  }
  
  export default DropdownResult2;