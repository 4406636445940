
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DropDownResult from './dropDownResult.jsx';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FormElem from '../info/FormElement';
import { sum } from './sum.js'
import { PMT } from './PMT.js'
import { priceItems, depositItems, monthlyItems, analysisItems } from '../data/data.js';
import Card from 'react-bootstrap/Card';
import DropDownResult2 from './dropDown2'

let analysisList;
 function CashFlowAnalysis(props) {
    

    const numberFormatter = Intl.NumberFormat('en-US')
    let pricesValue = []
    for (let x in props.prices) {
        if (!isNaN(x)) {
            pricesValue.push(parseInt(props.prices[x]))
        }
    }
    let totalPrice = sum(pricesValue)

    let depositList = [props.formData.deposit1, props.formData.deposit2, props.formData.deposit3, props.formData.deposit4, props.formData.deposit5, props.formData.deposit6]
    let deposits = []

    for (let x in depositList) {
        if (!isNaN(x)) {
            deposits.push(((parseFloat(depositList[x]) / 100) * totalPrice).toFixed(0))
        }
    }
    let sumDepo = sum(deposits);
    sumDepo = sumDepo.toFixed(0)

    // maintenance * indoor sqft
    // (tax/100 * totalPrice)/12
    // mortgage PMT

    let monthlyExpenses = []
    for (let x in props.expenses) {
        if (!isNaN(x)) {
            monthlyExpenses.push(parseFloat(props.expenses[x]))
        }
    }
    let maintenanceFee = (monthlyExpenses[0] * (props.formData.indoorSQFT)).toFixed(0)
    let tax = ((monthlyExpenses[1] / 100 * totalPrice) / 12).toFixed(0)
    //PMT
    let monthlyMortgage = PMT((monthlyExpenses[2] / 100) / 12, 300, sumDepo - totalPrice, 0, 1).toFixed(0)

    let totalMonthly = sum([maintenanceFee , tax , monthlyMortgage]).toFixed(0)

  
    
    function handlePrint(event) {
        event.preventDefault()
        // props.setPrint()
        // props.sentDataState()
        window.print()
    }
    

    analysisList = [
        totalMonthly, //break even
        (totalMonthly / props.formData.bedrooms).toFixed(0), //cost per bedroom
        (totalMonthly / props.formData.indoorSQFT).toFixed(0), //rent PSF
        (props.formData.rentPrice - totalMonthly).toFixed(0),   //monthly cash flow
        ((props.formData.rentPrice - totalMonthly) * 12).toFixed(0) //annual cash flow
    ]

    let annualROI = (((props.formData.rentPrice - totalMonthly) * 12) / sumDepo * 100).toFixed(2) //ROI
    //ROI button color
    let stonks = "danger"
    
    // ROI > 0
    if (annualROI > 0) {
        stonks = "success"
        
    }

    function setValue(value) {
        if (value === 0) {
            return ""
        }
        else {
            return value
        }
    }

    return (
        
        <Container className="py-4 " >
            <Card >
      <DropDownResult2
                                title="Cash Flow"
                                sum={analysisList[4]}
                                itemList={analysisItems}
                                itemsValue={analysisList}
                            />
      <Card.Body className="p-0" style={{ backgroundColor: "#413F42"}}>
        
      <Form className='shadow-lg px-3 py-4 rounded-4 ' style={{ backgroundColor: "#413F42" ,color:"white"}} onSubmit={props.handleSubmit}>
                <div className="text-center h1 fw-bold ">{props.name}</div>
                <Row >
                    <Col >
                        <DropDownResult
                            title="Total Price"
                            sum={totalPrice}
                            itemList={priceItems}
                            itemsValue={pricesValue}
                        />
                    </Col>

                    <Col>
                        <DropdownButton variant="warning" title={`Price/sqft: $${numberFormatter.format(parseInt(totalPrice / props.formData.indoorSQFT))}`} className="pb-3 dB">
                            <Dropdown.Item className="fw-light">Price per square feet (indoor)</Dropdown.Item>
                        </DropdownButton >
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <DropdownButton variant="warning" title={`Mortage: $${numberFormatter.format(totalPrice - sumDepo)}`} className="pb-3 dB">
                            <Dropdown.Item className="fw-light">Mortgage to be paid over 25 years.</Dropdown.Item>
                        </DropdownButton >

                    </Col>

                    <Col >
                        <DropDownResult
                            title="Deposits"
                            sum={sumDepo}
                            itemList={depositItems}
                            itemsValue={deposits}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <DropDownResult
                            title="Expenses"
                            sum={totalMonthly}
                            itemList={monthlyItems}
                            itemsValue={[maintenanceFee, tax, monthlyMortgage]}
                        />
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Row>
                        <Col>
                            <FormElem
                                handleChange={props.handleChange}
                                ID="Rent price"
                                name="rentPrice"
                                value={setValue(props.formData.rentPrice)}
                                place="Rental Income"
                            />
                        </Col>
                    </Row >

                    <Row >
                        {/* <Col>
                        
                            <DropDownResult2
                                title="Cash Flow"
                                sum={analysisList[4]}
                                itemList={analysisItems}
                                itemsValue={analysisList}
                            />
                        </Col> */}

                        <Col>
                            <DropdownButton variant={stonks} title={`Annual ROI: ${annualROI} %`} className="pb-3 dB">
                                <Dropdown.Item className="fw-light">Annual return on investment.</Dropdown.Item>
                            </DropdownButton >
                        </Col>
                    </Row>
                </Row>

                <Row className="justify-content-center ">
                    <Col lg={11} md={8}>
                        <div className="rounded-3 " style={{ backgroundColor: "lightgray" }}>
                            <Row className="g-3 pb-3  px-3 mt-0">
                                <Col>
                                    <Button className="rounded-3 fw-bold w-100" variant="dark" type="submit">Start over</Button>
                                </Col>
                                <Col>
                                    <Button onClick={handlePrint} className="rounded-3 fw-bold w-100" variant="light">Print</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Form>
      </Card.Body>
    </Card>
            
        </Container>
    )
}

export {CashFlowAnalysis, analysisList}