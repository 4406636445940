import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormElem from './FormElement';
import BasicExample from './Myaccordian';
import FormElemGroup from './fromElemGroup';
import { priceItems, depositItems, monthlyItems } from '../data/data.js';

export default function CondoInfoForm(props) {

    const priceListName = ["Price", "Parking", "Locker"]

    const maintenanceListName = ["maintenance", "municipalTax", "mortgage"]

    const depositListName = ["deposit2", "deposit3", "deposit4", "deposit5", "deposit6"]
    let depositList = [props.formData.deposit2, props.formData.deposit3, props.formData.deposit4
        , props.formData.deposit5, props.formData.deposit6]

    function setValue(value) {
        if (value === 0) {
            return ""
        }
        else {
            return value
        }
    }

    return (
        <Container className="py-4" >
            <Form className='shadow-lg px-3 py-4 rounded-4 ' style={{ backgroundColor: "#A5C9CA" }} onSubmit={props.handleSubmit}>
                <div className="text-center h1 fw-bold ">{props.name}</div>
                <Row >
                    <Col >
                        <FormElem
                            ID="Address"
                            handleChange={props.handleChange}
                            name="address"
                            type="text"
                            value={props.formData.address} 
                            place="Address"/>
                    </Col>
                </Row>

                <Row sm={1} lg={2}>
                   
                    <Col  >
                        <FormElem
                            ID="Indoor"
                            handleChange={props.handleChange}
                            name="indoorSQFT"
                            value={setValue(props.formData.indoorSQFT)}
                            min="10"
                            max="10000"
                            required={true} 
                            place="Indoor (sqft)"/>
                    </Col>
                    <Col  >
                        <FormElem
                            ID="Outdoor"
                            handleChange={props.handleChange}
                            name="outdoorSQFT"
                            value={setValue(props.formData.outdoorSQFT)}
                            min="0"
                            max="10000" 
                            place="Outdoor (sqft)"/>
                    </Col>
                </Row>

                <Row>
                    <Col  >
                        <FormElem
                            ID="Bedrooms"
                            handleChange={props.handleChange}
                            name="bedrooms"
                            value={setValue(props.formData.bedrooms)}
                            min="0"
                            max="10"
                            required={true} 
                            place="Bedrooms"/>
                    </Col>
                </Row>

                <Row  xs={1} md={2} >
                    <Col >
                        <FormElemGroup
                            buttonName="Total Price"
                            handleChange={props.handleChange}
                            collapseList={props.prices}
                            listName={priceListName}
                            setValue={() => setValue()}
                            min="0"
                            max="100000000"
                            required={true}
                            place={priceItems}
                        />
                    </Col>
                    <Col >
                        <FormElemGroup
                            buttonName="Maintenance"
                            handleChange={props.handleChange}
                            collapseList={props.expenses}
                            listName={maintenanceListName}
                            setValue={() => setValue()}
                            min="0"
                            max="100"
                            step={"0.01"}
                            required={true}
                            place={ [[`${monthlyItems[0]}`] , [`${monthlyItems[1]} %`],[`${monthlyItems[2]} %`] ] }
                        />
                    </Col>
                    </Row>
             
                <Row >
                    <Col >
                        <FormElem
                            ID="Occupancy"
                            handleChange={props.handleChange}
                            name="deposit1"
                            value={setValue(props.formData.deposit1)}
                            min="0"
                            max="100"
                            required={true} 
                            place={`${depositItems[0]} %`}/>
                    </Col>
                </Row>
                <Row>
                    <Col  >
                        <BasicExample
                            buttonName="Deposits"
                            handleChange={props.handleChange}
                            collapseList={depositList}
                            listName={depositListName}
                            setValue={() => setValue()}
                            min="0"
                            max="100"
                            step={"0.01"}
                            place={ [[`${depositItems[1]} %`] , [`${depositItems[2]} %`],[`${depositItems[3]} %`],[`${depositItems[4]} %`],[`${depositItems[5]} %`] ] }
                        />
                    </Col>
                </Row>

                <Button className="w-100 py-2 fw-bold rounded-4" variant="primary" type="submit">
                    Calculate value
                </Button>
            </Form>
        </Container>
    )
}

